import {Controller} from 'stimulus';

class AutocompleteController extends Controller {
  static targets = ['type', 'query', 'container']

  initialize() {
    this.search = this.debounce(this.search.bind(this), 500);
    this.currentQuery = '';
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(this, args), wait);
    };
  }

  search() {
    const query = {q: this.queryTarget.value};

    if (this.currentQuery != query.q && query.q.length > 0) {
      this.currentQuery = query.q;

      if (this.data.get('type-id')) {
        query.type_id = this.data.get('type-id');
      }
      fetch(`${this.data.get('url')}?${new URLSearchParams(query)}`)
        .then(response => response.text())
        .then(html => {
          this.containerTarget.innerHTML = html;
          this.showResults();
        })
        .catch(error => console.error('Error:', error));
    }
  }

  navigate(e) {
    switch (e.keyCode) {
      case 38:
        this.navigateUp();
        break;
      case 40:
        this.navigateDown();
        break;
    }
  }

  navigateUp() {
    const menu = this.containerTarget.getElementsByClassName('dropdown-menu')[0];
    const menuItems = menu.getElementsByClassName('dropdown-item');

    if (document.activeElement.classList.contains('dropdown-item')) {
      const currentIndex = [...menuItems].indexOf(document.activeElement);
      if (currentIndex > 0) {
        menuItems[currentIndex-1].focus();
      } else {
        // focus search box
        this.queryTarget.focus();
      }
    } else {
      // focus first item
      menuItems[0].focus();
    }
  }

  navigateDown() {
    const menu = this.containerTarget.getElementsByClassName('dropdown-menu')[0];
    const menuItems = menu.getElementsByClassName('dropdown-item');

    if (document.activeElement.classList.contains('dropdown-item')) {
      const currentIndex = [...menuItems].indexOf(document.activeElement);
      if (currentIndex < menuItems.length -1) {
        menuItems[currentIndex+1].focus();
      } else {
        // focus first item
        menuItems[0].focus();
      }
    } else {
      // focus first item
      menuItems[0].focus();
    }
  }

  toggleResults(e) {
    const menu = this.containerTarget.getElementsByClassName('dropdown-menu')[0];
    if (menu) {
      const menuItems = menu.getElementsByClassName('dropdown-item');
      if (e.relatedTarget && [...menuItems].indexOf(e.relatedTarget) >= 0 ||
          e.relatedTarget == this.queryTarget) {
        this.showResults();
      } else {
        this.hideResults();
      }
    }
  }

  showResults() {
    if (this.data.get('hide-class')) {
      this.containerTarget.classList.remove(this.data.get('hide-class'));
    } else {
      const dropdownMenu = this.containerTarget.getElementsByClassName('dropdown-menu')[0];
      if (dropdownMenu) {
        dropdownMenu.classList.add('show');
        document.body.classList.add('search-dropdown-show');
      }
    }
  }

  hideResults() {
    const dropdownMenu = this.containerTarget.getElementsByClassName('dropdown-menu')[0];
    if (dropdownMenu) {
      document.body.classList.remove('search-dropdown-show');
      setTimeout(() => {
        dropdownMenu.classList.remove('show');
      }, 200);
    }
  }

  close(e) {
    setTimeout(() => {
      this.containerTarget.classList.add(this.data.get('hide-class'));
    }, 200);
  }
}

export default AutocompleteController;
