import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    const options = {
      language: this.data.get('locale') || 'en',
      tags: (this.data.get('extensible') == 'true'),
      allowClear: true,
    };
    if (this.data.get('extensible') == 'true') {
      options['minimumInputLength'] = 2;
    }
    if (this.data.get('placeholder')) {
      options['placeholder'] = this.data.get('placeholder');
      options['allowClear'] = true;
    }
    if (this.data.get('url')) {
      options['ajax'] = {
        url: this.data.get('url'),
        dataType: 'json',
        data: (params) => ({q: params.term, page: params.page || 1}),
        delay: 500,
      };
    }
    $(this.element).select2(options);

    // workaround to fire native events for select2's own events
    $(this.element).on('select2:select', function() {
      this.dispatchEvent(new Event('change', {bubbles: true})); // fire a native event
    });
    $(this.element).on('select2:open', function() {
      this.dispatchEvent(new Event('focusin', {bubbles: true})); // fire a native event
    });
    $(this.element).on('select2:close', function() {
      this.dispatchEvent(new Event('focusout', {bubbles: true})); // fire a native event
    });

    $(this.element).on('select2:open', function(e) {
      document.querySelector(`[aria-controls="select2-${e.target.id.replace(/[\[\]]/g, '')}-results"]`).focus();
    });
  }
}
